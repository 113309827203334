<script setup>
import { ref, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import screenSplitLargeLeft from "@/components/ui/baseComponents/screenSplitLargeLeft.vue";
import SchedulingUserData from "@/components/ui/MA/appraisals/SchedulingUserData.vue";
import ScheduleingInformation from "@/components/ui/MA/appraisals/schedulingInformation.vue";
import {
  GETALLAPPRAISALPOLICIES,
  GETAPPRAISALPOLICIESGETTER,
  SCHEDULEAPPRAISALACTION,
} from "@/store/modules/MA/appraisals/constants.js";

import Swal from "sweetalert2";
const store = useStore();
const router = useRouter();

const appraisalTypeSelected = ref("new");

const toast = inject("toast");
const currentUserData = ref(null);

const submit = async (data) => {
  if(!currentUserData.value){
    Swal.fire({
        title: "Error",
        text: "Please select an employee.",
        icon: "error",
      });
      return;
  }
  try {
    const obj = {
      practice: store.getters["MAgeneralModule/getCurrentPractice"],
      user: currentUserData.value.id,
      type: appraisalTypeSelected.value,
      department: currentUserData.value.department_id,
      ...data,
    };
    const response = await store.dispatch(
      `Appraisals/${SCHEDULEAPPRAISALACTION}`,
      obj
    );
    if (response.status === 201) {
      Swal.fire({
        title: "Done",
        text: "Appraisal Scheduled Successfully",
        icon: "success",
      });
      router.push({
        name: "ma-appraisals",
      });
    }
  } catch (error) {
    toast.show(error);
  }
};
const appraisalTypes = [
  { title: "New", value: "new" },
  {
    title: "Follow up",
    value: "follow-up",
  },
];

const dataObject = {
  module: "Appraisals",
  action: GETALLAPPRAISALPOLICIES,
  getter: GETAPPRAISALPOLICIESGETTER,
  fetchWithRole: true,
  appendData: false,
};

const reRoute = () => {
  router.back();
};
</script>
<template>
  <screen-split-large-left>
    <template v-slot:leftSide>
      <div
        class="flex w-full items-center text-teal ml-4 mt-2 cursor-pointer w-24"
        @click="reRoute"
      >
        <span class="material-icons-outlined"> arrow_back </span>
        go Back
      </div>
      <SchedulingUserData
        v-model:user="currentUserData"
        :moduleConfig="dataObject"
        :mainTitle="'Employee Search'"
      />
    </template>
    <template v-slot:rightSide
      ><ScheduleingInformation
        :secondaryTitle="'appraisal'"
        @submitForm="submit"
        :title="'Schedule Appraisal'"
      >
        <template #extrafeatures>
          <div
            class="
              flex flex-col
              w-full
              bg-lightGrey
              rounded-xl
              text-grey
              justify-center
              pb-5
              py-2
            "
          >
            <div class="w-3/4 flex flex-col m-auto">
              <span class="self-start text-xl font-bold">Appraisal Type</span>
              <div class="flex justify-between mt-4">
                <label
                  class="inline-flex items-center"
                  v-for="item in appraisalTypes"
                  :key="item.value"
                >
                  <input
                    v-model="appraisalTypeSelected"
                    type="radio"
                    class="form-radio text-indigo-600"
                    name="appraisalType"
                    :value="item.value"
                  />
                  <span class="ml-2">{{ item.title }}</span>
                </label>
              </div>
            </div>
          </div>
        </template>
      </ScheduleingInformation>
    </template>
  </screen-split-large-left>
</template>
